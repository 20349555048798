import React from "react"
import Layout from "../components/layout"

const datenschutz = () => {
  return (
    <Layout>
      <h1>Datenschutzerklärung</h1>
      <p>
        <strong>Allgemeiner Hinweis und Pflichtinformationen</strong>
      </p>
      <p>
        <strong>Benennung der verantwortlichen Stelle</strong>
      </p>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        <span id="s3-t-firma">Ink and Feather Design Studios</span>
        <br />
        <span id="s3-t-ansprechpartner">Jürgen Appel</span>
        <br />
        <span id="s3-t-strasse">Lipper Weg 121</span>
        <br />
        <span id="s3-t-plz">45772</span> <span id="s3-t-ort">Marl</span>
      </p>
      <p></p>
      <p>
        Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen
        über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        (z.B. Namen, Kontaktdaten o. Ä.).
      </p>

      <p>
        <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
      </p>
      <p>
        Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
        Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
        Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
        formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf
        erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>

      <p>
        <strong>
          Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
        </strong>
      </p>
      <p>
        Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
        Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
        der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz
        unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten bereit:{" "}
        <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </a>
        .
      </p>

      <p>
        <strong>Recht auf Datenübertragbarkeit</strong>
      </p>
      <p>
        Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
        Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
        an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt
        in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung
        der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
        soweit es technisch machbar ist.
      </p>

      <p>
        <strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong>
      </p>
      <p>
        Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
        Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren
        Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über
        die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
      </p>

      <p>
        <strong>SSL- bzw. TLS-Verschlüsselung</strong>
      </p>
      <p>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website
        eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
        Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
        und am Schloss-Symbol in der Browserzeile.
      </p>

      <p>
        <strong>Server-Log-Dateien</strong>
      </p>
      <p>
        In Server-Log-Dateien erhebt und speichert der Provider der Website
        automatisch Informationen, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <ul>
        <li>Besuchte Seite auf unserer Domain</li>
        <li>Datum und Uhrzeit der Serveranfrage</li>
        <li>Browsertyp und Browserversion</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>IP-Adresse</li>
      </ul>
      <p>
        Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen
        statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b
        DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>

      <p>
        <strong>Kontaktformular</strong>
      </p>
      <p>
        Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
        Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
        für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet
        ohne Ihre Einwilligung nicht statt.
      </p>
      <p>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
        DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit
        möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail.
        Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      </p>
      <p>
        Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
        Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen -
        bleiben unberührt.
      </p>

      <p>
        <strong>YouTube</strong>
      </p>
      <p>
        Für Integration und Darstellung von Videoinhalten nutzt unsere Website
        Plugins von YouTube. Anbieter des Videoportals ist die YouTube, LLC, 901
        Cherry Ave., San Bruno, CA 94066, USA.
      </p>
      <p>
        Bei Aufruf einer Seite mit integriertem YouTube-Plugin wird eine
        Verbindung zu den Servern von YouTube hergestellt. YouTube erfährt
        hierdurch, welche unserer Seiten Sie aufgerufen haben.
      </p>
      <p>
        YouTube kann Ihr Surfverhalten direkt Ihrem persönlichen Profil
        zuzuordnen, sollten Sie in Ihrem YouTube Konto eingeloggt sein. Durch
        vorheriges Ausloggen haben Sie die Möglichkeit, dies zu unterbinden.
      </p>
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
        Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Einzelheiten zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von YouTube unter:{" "}
        <a href="https://www.google.de/intl/de/policies/privacy">
          https://www.google.de/intl/de/policies/privacy
        </a>
        .
      </p>

      <p>
        <strong>Twitter Plugin</strong>
      </p>
      <p>
        Unsere Website vewendet Funktionen des Dienstes Twitter. Anbieter ist
        die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA
        94103, USA.{" "}
      </p>
      <p>
        Bei Nutzung von Twitter und der Funktion "Re-Tweet" werden von Ihnen
        besuchte Websites mit Ihrem Twitter-Account verknüpft und in Ihrem
        Twitter-Feed veröffentlicht. Dabei erfolgt eine Übermittlung von Daten
        an Twitter. Über den Inhalt der übermittelten Daten sowie die Nutzung
        dieser Daten durch Twitter haben wir keine Kenntnis. Einzelheiten finden
        Sie in der Datenschutzerklärung von Twitter:{" "}
        <a href="https://twitter.com/privacy">https://twitter.com/privacy</a>.
      </p>
      <p>
        Sie können Ihre Datenschutzeinstellungen bei Twitter ändern:{" "}
        <a href="https://twitter.com/account/settings">
          https://twitter.com/account/settings
        </a>
      </p>

      <p>
        <strong>Google Web Fonts</strong>
      </p>
      <p>
        Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google
        Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
        gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon
        welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den
        Abruf der Google Web Fonts von einem Server von Google in den USA und
        der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es
        sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der
        Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes
        Interesse an der optimalen Darstellung und Übertragung unseres
        Webauftritts.
      </p>
      <p>
        Das Unternehmen Google ist für das us-europäische
        Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
        Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden
        Datenschutzniveaus gewährleisten.
      </p>
      <p>
        Einzelheiten über Google Web Fonts finden Sie unter:{" "}
        <a href="https://www.google.com/fonts#AboutPlace:about">
          https://www.google.com/fonts#AboutPlace:about
        </a>{" "}
        und weitere Informationen in den Datenschutzbestimmungen von Google:{" "}
        <a href="https://policies.google.com/privacy/partners?hl=de">
          https://policies.google.com/privacy/partners?hl=de
        </a>
      </p>
      <p>
        <small>
          Quelle: Datenschutz-Konfigurator von{" "}
          <a
            href="http://www.mein-datenschutzbeauftragter.de"
            target="_blank"
            rel="noreferrer"
          >
            mein-datenschutzbeauftragter.de
          </a>
        </small>
      </p>
    </Layout>
  )
}

export default datenschutz
